<template>
  <section
    class="information-section">
    <b-jumbotron
      class="contained"
      :header="translate(section.jumbotron.header, section.jumbotron.headerDefault)"
      :lead="translate(section.jumbotron.lead, section.jumbotron.leadDefault)"/>

    <article
      class="information-article contained"
      :class="{'open' : openMethod == `${section.key}-${methodIndex}`}"
      v-for="(method, methodIndex) in methods"
      :key="methodIndex">
      <div
        class="information-article-header"
        @click="toggleAccordion(methodIndex)">
        <div class="label">
          {{ translate(`method-${method.methodId}-contentTitle`, methodById(method.methodId).contentTitle) }}
        </div>

        <div class="article-header-button-wrapper">
          <b-button
            class="round"
            variant="outline-info">
            <font-awesome-icon
              :icon="['fal', openMethod == `${section.key}-${methodIndex}` ? 'times' : 'plus']" />
          </b-button>
        </div>
      </div>

      <div
        class="accordion-content"
        v-show="openMethod == `${section.key}-${methodIndex}`">
        <div
          class="info-section"
          :class="infoSection.type"
          v-for="(infoSection, infoSectionIndex) in infoSections"
          :key="infoSectionIndex">
          <div
            class="label"
            :class="infoSection.type">
            <span class="icon-wrapper">
              <font-awesome-icon
                :icon="infoSection.icon" />
            </span>
            <span class="label-text">{{ translate(infoSection.label, infoSection.labelDefault) }}</span>
          </div>
          <div class="info-section-content">
            <ul
              class="info-section-points"
              v-if="filteredRelevantMethodScoresByMethodIdAndType(method.methodId, infoSection.type).length">
              <li
                v-for="(relevantMethod, relevantMethodIndex) in filteredRelevantMethodScoresByMethodIdAndType(method.methodId, infoSection.type)"
                :key="relevantMethodIndex">{{ translate(answerProsConsLabelsToTranslate(relevantMethod.questionId, relevantMethod.refDataId, infoSection.type), null) }}</li>
            </ul>

            <ul
              class="info-section-points"
              v-else>
              <li>{{ translate(noneFoundLabel, noneFoundLabelDefault) }}</li>
            </ul>
          </div>
        </div>
        <div class="info-section-button-wrapper">
          <b-button
            v-if="isApp"
            @click="goToMethod(method)"
            block
            variant="outline-info"
            class="expanded">
            {{ translate(moreInfoButton.text, moreInfoButton.textDefault) }}
          </b-button>
          <b-button
            v-else
            :href="programme.mainExternalUrl"
            block
            variant="outline-info"
            class="expanded">
            {{ translate(moreInfoButton.text, moreInfoButton.textDefault) }}
          </b-button>
        </div>
      </div>
    </article>
    <section class="action-buttons-wrapper contained">
      <section
        class="action-button-container"
        v-if="showStartAgain && actionButton">
        <b-button
            @click="startAgain()"
            block
            variant="primary"
            class="leading">
            <font-awesome-icon :icon="actionButton.icon"/>
            <span class="button-text">{{ translate(actionButton.text, actionButton.textDefault) }}</span>
        </b-button>
      </section>

      <section
        class="get-a-method-button-wrapper"
        v-if="!isApp">
        <b-button
          block
          variant="secondary"
          class="leading"
          :disabled="loading"
          :href="programme.mainExternalUrl">
          <font-awesome-icon :icon="getAMethodButton.icon" />
          <span class="button-text">{{ translate(getAMethodButton.text, getAMethodButton.textDefault) }}</span>
        </b-button>
      </section>
    </section>
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import {
  filter,
  snakeCase,
  take,
  takeRight,
} from 'lodash';

export default {
  name: 'RecommendationsAccordion',

  props: {
    section: Object,
    methods: Array,
    infoSections: Array,
    openMethod: String,
    showStartAgain: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    loading: false,
    pageTitle: 'recommendations',
    moreInfoButton: {
      text: 'moreAboutMethod',
      textDefault: 'More information',
    },
    getAMethodButton: {
      text: 'getAMethod',
      textDefault: 'Get a method',
      link: '#',
      icon: ['fas', 'flag'],
    },
    noneFoundLabel: 'noneFound',
    noneFoundLabelDefault: 'None found',
    actionButton: {
      text: 'startAgain',
      textDefault: 'Start Again',
      icon: ['fas', 'arrow-left'],
    },
  }),

  computed: {
    ...mapGetters([
      'isApp',
    ]),

    ...mapGetters('assessment', [
      'answerProsConsLabelsByQuestionAndRefDataId',
    ]),

    ...mapGetters('method', [
      'methodById',
      'relevantMethodScores',
      'uniqueRelevantMethodScores',
      'relevantMethodScoresByMethodId',
      'relevantMethodScoresByMethodIdAndType',
    ]),

    ...mapGetters('programme', [
      'programme',
    ]),

    ...mapGetters('translations', [
      'translate',
    ]),
  },

  methods: {
    ...mapActions('assessment', [
      'resetAssessmentAnswers',
    ]),

    filteredRelevantMethodScoresByMethodIdAndType(methodId, type) {
      return filter(this.relevantMethodScoresByMethodIdAndType(methodId, type), function (method) {
        return this.answerProsConsLabelsToTranslate(method.questionId, method.refDataId, type);
      }.bind(this));
    },

    toggleAccordion(methodIndex) {
      this.$emit('toggle', `${this.section.key}-${methodIndex}`);
    },

    methodLink(method) {
      return { name: 'MethodInformation', params: { methodId: method.methodId, methodName: snakeCase(method.methodName) } };
    },

    answerProsConsLabelsToTranslate(questionId, refDataId, type) {
      return this.answerProsConsLabelsByQuestionAndRefDataId(questionId, refDataId, type) ?? false;
    },

    goToMethod(method) {
      if (this.isApp) {
        this.$router.push(this.methodLink(method));
        return;
      }
      this.$router.push('#');
    },

    async startAgain() {
      await this.resetAssessmentAnswers();
      this.$router.push('/');
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/base/_mixins.scss";

.info-section {
  @include media(tablet-desktop) {
    width: 50%;
    &.ideal {
      flex-grow: 2;
    }

    .info-section-content {
      // width: 180px;
      margin-left: auto;
      margin-right: auto;
    }
  }
}

.action-buttons-wrapper {
  margin-left: auto;
  margin-right: auto;
  margin-top: 1.313rem;
  margin-bottom: 1.313rem;

  @include media(tablet-desktop) {
    display: flex;
    margin-top: 2.106rem;
    margin-bottom: 2.106rem;
  }

  .get-a-method-button-wrapper {

    @include media(tablet-desktop) {
      padding-top: 0;
      padding-bottom: 0;

      margin: 0 0.438rem 0;

      a {
        width: fit-content;
        min-width: 215px;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
  .action-button-container {
      padding-bottom: 0.75rem;
  }

  @include media(tablet-desktop) {
    display: flex;
    justify-content: center;
    padding-bottom: 0;

    .action-button-container {
      padding-bottom: 0rem;
      button {
        width: fit-content;
        min-width: 215px;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
}
</style>
