<template>
  <div class="information accordion-info">
    <div class="page-title">
      {{ translate(pageTitle, pageTitle) }}
    </div>

    <section class="sections">
      <RecommendationsAccordion
        v-if="methodsRecommendedForYou"
        :section="sections.recomended"
        :infoSections="infoSections"
        :methods="methodsRecommendedForYou"
        :openMethod="openMethod"
        v-on:toggle="toggleMethodOpen"
        :showStartAgain="!otherMethodsToConsider.length"/>
      <article v-else>
        {{ translate(noMethodsAvailable.text, noMethodsAvailable.textDefault) }}
      </article>
      <RecommendationsAccordion
        v-if="otherMethodsToConsider.length"
        :section="sections.toCompare"
        :infoSections="infoSections"
        :methods="otherMethodsToConsider"
        :openMethod="openMethod"
        v-on:toggle="toggleMethodOpen"
        :showStartAgain="true"/>
    </section>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import {
  filter,
  snakeCase,
  take,
  takeRight,
} from 'lodash';
import RecommendationsAccordion from '@/components/RecommendationsAccordion';

export default {
  name: 'Recommendations',

  components: {
    RecommendationsAccordion,
  },

  data: () => ({
    pageTitle: 'recommendations',
    jumbotron: {
      header: 'recommendationTitle',
      headerDefault: 'Top recommendations for you',
      lead: 'recommendationSubtitle',
      leadDefault: 'Based on best fit and effectiveness. Click on each method to see pros and cons for you.',
    },
    sections: {
      recomended: {
        key: 'recommended',
        jumbotron: {
          header: 'recommendationTitle',
          headerDefault: 'Top recommendations for you',
          lead: 'recommendationSubtitle',
          leadDefault: 'Based on best fit and effectiveness. Click on each method to see pros and cons for you.',
        },
      },
      toCompare: {
        key: 'toCompare',
        jumbotron: {
          header: 'recommendationOtherMethodsTitle',
          headerDefault: 'Other methods to consider',
          lead: 'recommendationOtherMethodsSubtitle',
          leadDefault: 'The following methods are less effective or are less suited to you, based on your answers.',
        },
      },
    },
    infoSections: [
      {
        label: 'prosConsIdeal',
        labelDefault: 'Ideal for you',
        type: 'ideal',
        icon: ['fas', 'check-circle'],
      },
      {
        label: 'prosConsImperfect',
        labelDefault: 'Imperfect fit',
        type: 'imperfect',
        icon: ['fas', 'times-circle'],
      },
      {
        label: 'prosConsNotAMatch',
        labelDefault: 'Things to consider',
        type: 'notAMatch',
        icon: ['fas', 'question-circle'],
      },
    ],
    noMethodsAvailable: {
      text: 'noMethodsAvailable',
      textDefault: 'No methods available for you',
    },
    openMethod: null,
  }),

  mounted() {
    this.saveQuestionaire();
  },

  computed: {

    ...mapGetters('method', [
      'methodById',
      'relevantMethodScores',
      'uniqueRelevantMethodScores',
    ]),

    ...mapGetters('translations', [
      'translate',
    ]),

    methodsRecommendedForYou() {
      return take(this.uniqueRelevantMethodScores, 3);
    },
    otherMethodsToConsider() {
      return takeRight(this.uniqueRelevantMethodScores, this.uniqueRelevantMethodScores.length - this.methodsRecommendedForYou.length);
    },
  },

  methods: {
    ...mapActions([
      'saveQuestionaire',
    ]),

    ...mapActions('assessment', [
      'resetAssessmentAnswers',
    ]),

    toggleMethodOpen(uniqueKey) {
      let methodToToggle = uniqueKey;
      if (this.openMethod === methodToToggle) {
        methodToToggle = null;
      }
      this.openMethod = methodToToggle;
    },

    async startAgain() {
      await this.resetAssessmentAnswers();
      this.$router.push('/');
    },
  },
};
</script>
